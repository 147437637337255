import React from "react"
const LogoIsoTipo = () => {
  return (
    <svg
      className="logoIsoTipoSvg"
      style={{ width: "50px" }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 247.28 241.77"
    >
      <defs></defs>
      <title>logoIsotipo</title>
      <g id="Capa_2" data-name="Capa 2">
        <g id="Capa_1-2" data-name="Capa 1">
          <path
            className="cls-1"
            d="M192.54,45.64A101,101,0,0,0,54.74,45c40.36,13,68.58,49.81,68.87,94.46C123.86,95.53,153.18,59.26,192.54,45.64Z"
          />
          <path
            className="cls-1"
            d="M213.32,177.8A101.08,101.08,0,0,0,228.67,101a100.8,100.8,0,0,0-83.86,45.72C173.28,150.59,197.71,162,213.32,177.8Z"
          />
          <path
            className="cls-1"
            d="M102.47,146.75A100.8,100.8,0,0,0,18.61,101,101.08,101.08,0,0,0,34,177.8C49.57,162,74,150.59,102.47,146.75Z"
          />
          <path
            className="cls-1"
            d="M139.3,146.11a100.92,100.92,0,0,1,86.79-55.2A101.17,101.17,0,0,0,206,55a101.06,101.06,0,0,0-77.32,90.46Q134.06,145.59,139.3,146.11Z"
          />
          <path
            className="cls-1"
            d="M108,146.11q5.24-.52,10.63-.69A101.07,101.07,0,0,0,41.29,55a101.17,101.17,0,0,0-20.1,35.94A100.92,100.92,0,0,1,108,146.11Z"
          />
          <path
            className="cls-1"
            d="M123.64,241.77C55.46,241.77,0,187.54,0,120.88S55.46,0,123.64,0,247.28,54.23,247.28,120.88,191.82,241.77,123.64,241.77Zm0-233.68C59.92,8.09,8.09,58.69,8.09,120.88s51.84,112.8,115.55,112.8,115.55-50.6,115.55-112.8S187.36,8.09,123.64,8.09Z"
          />
        </g>
      </g>
    </svg>
  )
}
export default LogoIsoTipo
